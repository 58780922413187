// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.production.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_SERVER_URL: 'https://dev.adcornera4.com',
  GRAPHQL_API_URL: 'https://dev.adcornera4.com/graphql',
  UPLOAD_FILES_URL: 'https://dev.adcornera4.com/graphql/api/v1/file',
  DOWNLOAD_FILE_URL: 'https://dev.adcornera4.com/graphql/api/v1/file',
  CUSTOM_LIST_URL: 'https://dev.adcornera4.com/graphql/api/v1/custom-audience',
  AUTH0_DOMAIN: 'auth-staging.ad-connect.io',
  AUTH0_CLIENT_ID: 'YsbqSxrF5KhF8PzecvwWKHfvUVWMSSAT',
  PENDO_API_KEY: 'b74386ae-5812-457c-4993-926123013b05',
  AD_MESSENGER_URL: 'https://zappspot.com',
  USER_API_URL: 'https://dev.adcornera4.com/api/users',
  LOGOUT_URL: 'https://dev.adcornera4.com/ui/logout',
  BILLING_API_URL: 'https://dev.adcornera4.com/api/billing',
  ORGANIZATION_API_URL: 'https://dev.adcornera4.com/api/organizations',
  REPORTING_API_URL: 'https://dev.adcornera4.com/api/reports',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
