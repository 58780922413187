import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

import { ChannelName } from '@interfaces';

const channelRequirementsLinkMap = {
  [ChannelName.GOOGLE]:
    'https://developers.google.com/google-ads/api/docs/remarketing/audience-segments/customer-match/get-started',
  [ChannelName.FACEBOOK]: 'https://developers.facebook.com/docs/marketing-api/audiences/guides/custom-audiences',
  [ChannelName.INSTAGRAM]: 'https://developers.facebook.com/docs/marketing-api/audiences/guides/custom-audiences',
};

@Component({
  selector: 'app-upload-list-dialog',
  templateUrl: './upload-list-dialog.component.html',
  styleUrls: ['./upload-list-dialog.component.scss'],
})
export class UploadListDialogComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<UploadListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public uploadListData: { channelName: ChannelName; fileName: string },
  ) {}

  public channelRequirementsLink: string;
  public file: File;
  public fileName: string;

  ngOnInit(): void {
    this.channelRequirementsLink = channelRequirementsLinkMap[this.uploadListData.channelName];
    if (this.uploadListData.fileName) {
      this.fileName = this.uploadListData.fileName;
    }
  }

  onFileDropped(files: NgxFileDropEntry[]) {
    const file = files[0];
    if (file.fileEntry.isFile) {
      const fileEntry = file.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.file = file;
        this.fileName = file.name;
      });
    }
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      this.file = file;
      this.fileName = file.name;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  submitDialog(): void {
    this.dialogRef.close({ file: this.file });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
