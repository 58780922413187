import { Injectable } from '@angular/core';
import { ApolloError } from '@apollo/client/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {
  Campaign,
  CampaignTemplate,
  IBasicCampaignTemplate,
  CampaignTemplateInput,
  ICampaignProcessingStatusType,
  ICampaignTemplateRequestType,
  IDenyOrAccessCampaignTemplateRequestType,
  ICampaignOperation,
  ICampaignUpdateInput,
  IUser,
  ICampaignTemplateFeedback,
} from '@interfaces';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { map, concatMap, catchError, mergeMap } from 'rxjs/operators';
import { isPlainObject, omit, trim } from 'lodash';

import { AppConfigService } from '../app-config.service';
import { campaignTemplateRequestNotification } from '../notification/notification.gql';
import * as gql from './campaign.gql';
import { AuthService } from '../auth/auth.service';
import { Params } from '@angular/router';
import { convertJsonToUrlQueryParams } from '../../../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private appConfig: AppConfigService,
    private authService: AuthService,
  ) {}

  /*****ZOR****/

  campaignsByRequestingUserForAdSpend(): Observable<Array<Campaign>> {
    return this.apollo
      .watchQuery<{ campaignsByRequestingUserForAdSpend: Array<Campaign> }>({
        fetchPolicy: 'network-only',
        query: gql.getCampaignsByRequestingUserForAdSpend,
      })
      .valueChanges.pipe(
        map(({ data }) => data.campaignsByRequestingUserForAdSpend),
        catchError(() => of([])),
      );
  }

  campaignsByViewTypeForAdSpend(): Observable<Array<Campaign>> {
    return this.apollo
      .watchQuery<{ campaignsByViewTypeForAdSpend: Array<Campaign> }>({
        fetchPolicy: 'network-only',
        query: gql.getCampaignsByViewTypeForAdSpend,
      })
      .valueChanges.pipe(
        map(({ data }) => data.campaignsByViewTypeForAdSpend),
        catchError(() => of([])),
      );
  }

  getCampaignTemplateFeedback(campaignTemplateId: number): Observable<CampaignTemplate> {
    return this.apollo
      .query<{ campaignTemplate: CampaignTemplate }>({
        fetchPolicy: 'network-only',
        query: gql.getCampaignTemplateFeedback,
        variables: {
          id: campaignTemplateId,
        },
      })
      .pipe(
        map(({ data }) => data.campaignTemplate),
        catchError(() => of(null)),
      );
  }

  createCampaignTemplateFeedback(
    campaignId: number,
    feedbackInput: { rate: number; comment: string },
  ): Observable<ICampaignTemplateFeedback> {
    return this.apollo
      .mutate<{ createCampaignTemplateFeedback: ICampaignTemplateFeedback }>({
        mutation: gql.createCampaignTemplateFeedback,
        variables: {
          campaignId,
          campaignTemplateFeedback: feedbackInput,
        },
      })
      .pipe(
        map(({ data }) => data.createCampaignTemplateFeedback),
        catchError(() => of(null)),
      );
  }

  updateCampaignTemplateFeedback(
    id: number,
    feedbackInput: { rate: number; comment: string },
  ): Observable<ICampaignTemplateFeedback> {
    return this.apollo
      .mutate<{ updateCampaignTemplateFeedback: ICampaignTemplateFeedback }>({
        mutation: gql.updateCampaignTemplateFeedback,
        variables: {
          id,
          campaignTemplateFeedback: feedbackInput,
        },
      })
      .pipe(
        map(({ data }) => data.updateCampaignTemplateFeedback),
        catchError(() => of(null)),
      );
  }

  /*****COMMON*****/
  getCampaignTemplateByID(id: string): Observable<CampaignTemplate> {
    return this.apollo
      .watchQuery<{ campaignTemplate: CampaignTemplate }>({
        fetchPolicy: 'network-only',
        query: gql.getCampaignTemplateByID,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => data.campaignTemplate),
        concatMap((res: any) => this.getSignedUrls(res) as Observable<CampaignTemplate>),
        catchError((error: ApolloError) => throwError(error)),
      );
  }

  createCampaign(campaign: any): Observable<Campaign> {
    const cleanCampaign = this.omitTypename(campaign);
    return this.apollo
      .mutate<{ addCampaign: Campaign }>({
        mutation: gql.addCampaign,
        variables: {
          input: cleanCampaign,
        },
      })
      .pipe(
        map(({ data }) => data.addCampaign),
        catchError(() => of(null)),
      );
  }

  archiveCampaign(id: string): Observable<boolean> {
    return this.apollo
      .mutate<{ archiveCampaign: boolean }>({
        mutation: gql.archiveCampaign,
        variables: {
          id,
        },
      })
      .pipe(
        map(({ data }) => data.archiveCampaign),
        catchError(() => of(null)),
      );
  }

  getCampaignByID(id: string): Observable<Campaign> {
    return this.apollo
      .watchQuery<{ campaign: Campaign }>({
        fetchPolicy: 'network-only',
        query: gql.getCampaignByID,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const result = Object.assign({}, data.campaign);
          const channel = {};
          channel[result.campaignChannelNme] = result.channel;
          result.channel = channel;
          return result;
        }),
        catchError((error: ApolloError) => throwError(error)),
      );
  }

  private omitTypename(obj): any {
    const cleanObj = Object.assign({}, omit(obj, ['__typename']));
    for (const prop in cleanObj) {
      if (isPlainObject(cleanObj[prop])) {
        cleanObj[prop] = this.omitTypename(cleanObj[prop]);
      }
    }
    return cleanObj;
  }

  createCampaignTemplate(campaignTemplateInput: any, filenames: Array<string>): Observable<CampaignTemplate> {
    campaignTemplateInput = Object.assign({}, campaignTemplateInput);
    const variables = {
      templateInput: campaignTemplateInput,
      filenames,
    };
    if (!filenames) {
      delete variables.filenames;
    }
    return this.apollo
      .mutate<{ addCampaignTemplate: CampaignTemplate }>({
        mutation: gql.addCampaignTemplate,
        variables,
      })
      .pipe(
        map(({ data }) => data.addCampaignTemplate),
        catchError(() => of(null)),
      );
  }

  getBasicCampaignTemplatesViewType(): Observable<Array<IBasicCampaignTemplate>> {
    return this.apollo
      .watchQuery<{ basicCampaignTemplatesViewType: Array<IBasicCampaignTemplate> }>({
        query: gql.getBasicCampaignTemplatesViewType,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.basicCampaignTemplatesViewType),
        catchError(() => of([])),
      );
  }

  updateCampaignTemplateById(
    id: string,
    campaignTemplate: CampaignTemplateInput,
    filenames: Array<string>,
  ): Observable<CampaignTemplate> {
    const variables = {
      id,
      campaignTemplate,
      filenames,
    };
    if (!filenames) {
      delete variables.filenames;
    }
    return this.apollo
      .mutate<{ updateCampaignTemplateById: CampaignTemplate }>({
        mutation: gql.updateCampaignTemplateById,
        variables,
      })
      .pipe(
        map(({ data }) => data.updateCampaignTemplateById),
        catchError(() => of(null)),
      );
  }

  cloneCampaignTemplateById(
    id: string,
    campaignTemplate: CampaignTemplateInput,
    filenames?: Array<string>,
  ): Observable<CampaignTemplate> {
    const variables = {
      id,
      campaignTemplate,
      filenames,
    };
    if (!filenames) {
      delete variables.filenames;
    }
    return this.apollo
      .mutate<{ cloneCampaignTemplateById: CampaignTemplate }>({
        mutation: gql.cloneCampaignTemplateById,
        variables,
      })
      .pipe(
        map(({ data }) => data.cloneCampaignTemplateById),
        catchError(() => of(null)),
      );
  }

  activeCampaignTemplateById(id: string, active: boolean): Observable<boolean> {
    return this.apollo
      .mutate<{ activeCampaignTemplate: boolean }>({
        mutation: gql.activeCampaignTemplate,
        variables: {
          id,
          active,
        },
      })
      .pipe(
        map(({ data }) => data.activeCampaignTemplate),
        catchError(() => of(false)),
      );
  }

  convertChannelData(campaign): any {
    const campaignData = Object.assign({}, campaign);
    const channelData = {};
    channelData[campaign.campaignChannelNme] = campaign.channel;
    campaignData.channel = channelData;
    return campaignData;
  }

  // New for restyling

  // For zee home page and run ad list
  getZeeCampaignTemplateMainInfo(): Observable<Array<CampaignTemplate>> {
    return this.apollo
      .watchQuery<{ getSharedTemplates: Array<CampaignTemplate> }>({
        fetchPolicy: 'network-only',
        query: gql.getZeeCampaignTemplateMainInfo,
      })
      .valueChanges.pipe(
        map(({ data }) => data.getSharedTemplates.map((campaignTemplate) => this.convertChannelData(campaignTemplate))),
        catchError(() => of([])),
      );
  }

  getCampaignsByRequestingUser(): Observable<Array<Campaign>> {
    return this.apollo
      .watchQuery<{ campaignsByRequestingUser: Array<Campaign> }>({
        fetchPolicy: 'network-only',
        query: gql.getCampaignsByRequestingUser,
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const campaignsData = data.campaignsByRequestingUser.map((campaigns) => this.convertChannelData(campaigns));
          return campaignsData;
        }),
        catchError(() => of([])),
      );
  }

  // For zor run ad list page
  getSharedTemplatesByViewType(): Observable<Array<CampaignTemplate>> {
    return this.apollo
      .watchQuery<{ getSharedTemplatesByViewType: Array<CampaignTemplate> }>({
        query: gql.getSharedTemplatesByViewType,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.getSharedTemplatesByViewType.map((campaigns) => this.convertChannelData(campaigns));
        }),
        catchError(() => of([])),
      );
  }

  operateCampaign(campaignOperation: ICampaignOperation): Observable<Partial<Campaign>> {
    return this.apollo
      .mutate<{ operateCampaign: Partial<Campaign> }>({
        mutation: gql.operateCampaign,
        variables: { campaignOperation },
      })
      .pipe(map(({ data }) => data.operateCampaign));
  }

  editCampaignData(campaignInfoArgs: ICampaignUpdateInput): Observable<Partial<Campaign>> {
    return this.apollo
      .mutate<{ editCampaign: Partial<Campaign> }>({
        mutation: gql.editCampaign,
        variables: { campaignInfoArgs },
      })
      .pipe(map(({ data }) => data.editCampaign));
  }

  getSignedUrls(item: Campaign | CampaignTemplate): Observable<Campaign | CampaignTemplate> {
    const res = Object.assign({}, item);
    let mediaUrls = [];
    const isCampaign = !!item['campaignId'];
    if (isCampaign) {
      mediaUrls = (res as Campaign).campaignImageUrls || [];
    } else {
      mediaUrls = (res as CampaignTemplate).campaignTemplateImageUrls || [];
    }
    mediaUrls = mediaUrls.filter((url) => !!url);
    const setMediaUrls = (urls: string[]): Campaign | CampaignTemplate => {
      if (isCampaign) {
        (res as Campaign).campaignImageUrls = urls;
      } else {
        (res as CampaignTemplate).campaignTemplateImageUrls = urls;
      }
      return res;
    };
    if (!!mediaUrls && mediaUrls.length > 0) {
      return this._get({ names: mediaUrls, action: 'read' }).pipe(
        map((signedUrlsRes) => {
          const signedUrls = signedUrlsRes['signedUrls'] || [];
          return setMediaUrls(signedUrls);
        }),
        catchError(() => {
          return of(setMediaUrls([]));
        }),
      );
    } else {
      return of(setMediaUrls([]));
    }
  }

  private _get(queryParameters: { [key: string]: any }): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    const baseUrl = this.appConfig.downloadFileURL;
    const parameterStr = Object.keys(queryParameters)
      .map((key) => {
        let res;
        res = queryParameters[key];
        if (typeof res !== 'string') {
          res = encodeURIComponent(JSON.stringify(queryParameters[key]));
        }
        return `${key}=${res}`;
      })
      .join('&');
    return this.http.get(`${baseUrl}${parameterStr ? '?' + parameterStr : ''}`, httpOptions);
  }

  uploadFiles(files: File[], user: IUser): Observable<string[]> {
    if (!files?.length) {
      return of([]);
    }

    const uploadFilenames = files.map((file: File, idx: number) => this.getUploadFilename(file, user, idx));
    return this.getSignedUrlsByUrls(uploadFilenames, 'write').pipe(
      mergeMap((signedUrls: string[]) =>
        forkJoin(signedUrls.map((signedUrl, idx) => (signedUrl ? this.uploadFile(signedUrl, files[idx]) : of(null)))),
      ),
      mergeMap(() => of(uploadFilenames)),
    );
  }

  uploadCustomAudienceFile(file: File, queryParameters: Params): Observable<any> {
    const baseUrl = this.appConfig.customListFacebookURL;
    const queryString = convertJsonToUrlQueryParams(queryParameters);

    const formData = new FormData();
    formData.append('custom-list', file);

    return this.http.post(`${baseUrl}${queryString}`, formData);
  }

  getUploadFilename(file: File, user: IUser, idx: number): string {
    const uid = user.userId;
    const filename = file.name;
    const timestamp = new Date().getTime();
    return `uid_${uid}/${timestamp}-${idx}-${trim(filename)}`;
  }

  private uploadFile(signedUrl: string, file: File): Observable<any> {
    return this.http.put(signedUrl, file);
  }

  transSignedUrlToDBUrl(signedUrl: string): string {
    let res = '';
    const encodeUrl = decodeURIComponent(signedUrl);
    const matchUrl = encodeUrl.match(/(\/)(.+)\?/);
    if (matchUrl && !!matchUrl[2]) {
      const urlPath = matchUrl[2].split('/');
      let uidPath = '';
      const fileName = urlPath[urlPath.length - 1];
      if (urlPath.length >= 2) {
        uidPath = urlPath[urlPath.length - 2];
      }
      if (uidPath.includes('uid')) {
        res = `${uidPath}/${fileName}`;
      } else {
        res = `${fileName}`;
      }
    }
    return res;
  }

  getSignedUrlsByUrls(mediaUrls: Array<string>, action = 'read'): Observable<Array<string>> {
    return this._get({ names: mediaUrls, action }).pipe(
      map((signedUrlsRes) => signedUrlsRes['signedUrls'] || []),
      catchError(() => of([])),
    );
  }

  getCampaignProcessingStatus(ids: number[]): QueryRef<{ trackCampaignEvents: Array<ICampaignProcessingStatusType> }> {
    return this.apollo.watchQuery<{ trackCampaignEvents: Array<ICampaignProcessingStatusType> }>({
      query: gql.getCampaignProcessingStatus,
      variables: { ids },
    });
  }

  cancelCampaignEvents(ids: number[]): Observable<Array<number>> {
    return this.apollo
      .mutate<{ cancelCampaignEvents: Array<number> }>({
        mutation: gql.cancelCampaignEvents,
        variables: {
          ids,
        },
      })
      .pipe(map(({ data }) => data.cancelCampaignEvents));
  }

  trackCampaignCancellation(
    ids: number[],
  ): QueryRef<{ trackCampaignCancellation: Array<ICampaignProcessingStatusType> }> {
    return this.apollo.watchQuery<{ trackCampaignCancellation: Array<ICampaignProcessingStatusType> }>({
      query: gql.trackCampaignCancellation,
      variables: {
        ids,
      },
    });
  }

  getZeeTemplateRequests(): Observable<Array<ICampaignTemplateRequestType>> {
    return this.apollo
      .watchQuery<{ getCampaignTemplateRequests: Array<ICampaignTemplateRequestType> }>({
        fetchPolicy: 'network-only',
        query: gql.getZeeTemplateRequests,
      })
      .valueChanges.pipe(
        map(({ data }) => data.getCampaignTemplateRequests),
        catchError(() => of([])),
      );
  }

  getCampaignTemplateRequestById(requestId: string): Observable<ICampaignTemplateRequestType> {
    return this.apollo
      .watchQuery<{ getCampaignTemplateRequestById: ICampaignTemplateRequestType }>({
        query: gql.getCampaignTemplateRequestById,
        variables: {
          id: requestId,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => data.getCampaignTemplateRequestById),
        catchError(() => of(null)),
      );
  }

  denyOrAccessCampaignTemplateRequest(
    input: IDenyOrAccessCampaignTemplateRequestType,
    isRefetchNotification: boolean = false,
    isRefetchRequestList: boolean = false,
  ): Observable<ICampaignTemplateRequestType> {
    let refetchQuery;
    if (isRefetchNotification) {
      refetchQuery = campaignTemplateRequestNotification;
    }
    if (isRefetchRequestList) {
      refetchQuery = gql.getZeeTemplateRequests;
    }
    return this.apollo
      .mutate<{ denyOrAccessCampaignTemplateRequest: ICampaignTemplateRequestType }>({
        mutation: gql.denyOrAccessCampaignTemplateRequest,
        variables: {
          input,
        },
        refetchQueries: refetchQuery
          ? [
              {
                query: refetchQuery,
              },
            ]
          : [],
      })
      .pipe(
        map(({ data }) => data.denyOrAccessCampaignTemplateRequest),
        catchError(() => of(null)),
      );
  }

  createZeeTemplateRequest(
    campaignTemplateInput: any,
    filenames: Array<string>,
  ): Observable<{ campaignTemplateRequestId: string }> {
    const variables = {
      templateInput: Object.assign({}, campaignTemplateInput),
    };
    if (filenames && filenames.length) {
      Object.assign(variables, {
        filenames,
      });
    }
    return this.apollo
      .mutate<{ requestNewCampaignTemplate: { campaignTemplateRequestId: string } }>({
        mutation: gql.createZeeCampaignTemplate,
        variables,
      })
      .pipe(
        map(({ data }) => data.requestNewCampaignTemplate),
        catchError(() => of(null)),
      );
  }
}
